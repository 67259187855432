
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const roomIdInput = ref("");
    const router = useRouter();
    const joinRoom = () => {
      var roomId: string = roomIdInput.value;
      if (roomId.length == 0) {
        roomId = Math.floor(Math.random() * 1000).toString();
      }
      router.push("/minesweeper2p/" + roomId);
    };
    return { roomIdInput, joinRoom };
  },
});

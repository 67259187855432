import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import player from "./modules/player";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export const store = new Vuex.Store({
  modules: { player },
  plugins: [vuexLocal.plugin],
});


import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  beforeMount() {
    this.input = this.playerName;
  },
  setup() {
    const store = useStore();
    const playerName = computed(() => store.state.player.playerName);
    const updatePlayerName = (playerName: string) =>
      store.dispatch("updatePlayerName", playerName);
    const input = ref("");
    const submit = () => {
      if (input.value == "") {
        input.value = playerName.value;
      } else {
        updatePlayerName(input.value);
      }
    };
    return {
      input,
      playerName,
      submit,
      updatePlayerName,
    };
  },
});


import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

const guestNames = [
  "Ampere",
  "Archimedes",
  "Aristotle",
  "Babbage",
  "Bacon",
  "Bell",
  "Berners-Lee",
  "Bernoulli",
  "Boltzmann",
  "Boole",
  "Born",
  "Boyle",
  "Bragg",
  "Broglie",
  "Bunsen",
  "Canter",
  "Cavendish",
  "Celsius",
  "Chu",
  "Compton",
  "Conway",
  "Copernicus",
  "Coulomb",
  "Curie",
  "Darwin",
  "Descartes",
  "Dijkstra",
  "Diophantus",
  "Dirac",
  "Edison",
  "Einstein",
  "Eratosthenes",
  "Euclid",
  "Euler",
  "Faraday",
  "Fermat",
  "Fermi",
  "Feynman",
  "Fibonacci",
  "Foucault",
  "Franklin",
  "Freud",
  "Galileo",
  "Galois",
  "Galvani",
  "Gauss",
  "Gibbs",
  "Haber",
  "Hawking",
  "Heisenberg",
  "Hertz",
  "Hilbert",
  "Hoare",
  "Hooke",
  "Hubble",
  "Huygens",
  "Joule",
  "Kepler",
  "Knuth",
  "Lagrange",
  "Lamarck",
  "Lamport",
  "Laplace",
  "Leibniz",
  "da Vinci",
  "Locke",
  "Lorentz",
  "Lovelace",
  "Mach",
  "Maxwell",
  "Morse",
  "Nash",
  "Neumann",
  "Newton",
  "Nobel",
  "Oppenheimer",
  "Pascal",
  "Pasteur",
  "Pauli",
  "Pavlov",
  "Planck",
  "Poincare",
  "Popper",
  "Pythagoras",
  "Ramanujan",
  "Riemann",
  "Rivest",
  "Rutherford",
  "Schrodinger",
  "Shamir",
  "Shannon",
  "Sutherland",
  "Tarjan",
  "Tesla",
  "Thompson",
  "Turing",
  "Wiles",
  "Wittgenstein",
  "Yang",
];

export default defineComponent({
  beforeMount() {
    if (this.playerName == "") {
      const guestName =
        guestNames[Math.floor(Math.random() * guestNames.length)];
      this.updatePlayerName(guestName);
    }
  },
  setup() {
    const store = useStore();
    const playerName = computed(() => store.state.player.playerName);
    const updatePlayerName = (playerName: string) => {
      store.dispatch("updatePlayerName", playerName);
    };
    document.addEventListener(
      "contextmenu",
      function (e) {
        e.preventDefault();
      },
      false
    );
    return { playerName, updatePlayerName };
  },
});

import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";

@Module
export default class player extends VuexModule {
  playerName = "";

  @Mutation
  setPlayerName(playerName: string) {
    this.playerName = playerName;
  }

  @Action({ commit: "setPlayerName" })
  updatePlayerName(playerName: string) {
    return playerName;
  }
}


import { defineComponent } from "vue";
import DollarHeader from "@/components/DollarHeader.vue";
import GameLobby from "@/components/GameLobby.vue";
import EditName from "@/components/EditName.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    DollarHeader,
    GameLobby,
    EditName,
  },
});

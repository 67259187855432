import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-dBg min-h-screen" }
const _hoisted_2 = { class: "container m-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DollarHeader = _resolveComponent("DollarHeader")!
  const _component_EditName = _resolveComponent("EditName")!
  const _component_GameLobby = _resolveComponent("GameLobby")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_DollarHeader, { title: "One Mistakes" }),
      _createVNode(_component_EditName),
      _createVNode(_component_GameLobby)
    ])
  ]))
}